html,
body,
#root,
#root > div {
  //height: 100%;
}
body {
  padding-bottom: 1px;
}

@-webkit-keyframes swing {
  15% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
  }
  50% {
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
  }
  65% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
  }
  80% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
  }
  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }
}
@keyframes swing {
  15% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
  }
  30% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
  }
  50% {
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
  }
  65% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
  }
  80% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
  }
  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }
}
.btn-profile-card:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

